import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import heroimage from "../assets/images/404_hero.png"
import PageNotFoundSVG from "../assets/svg/page-not-found.svg"

export default function Page404({data}) {
  return (
    <Layout pageTitle="Page Not Found" className="page_404" hideFooter>
      <img src={heroimage} alt="Page not found!" className="hero_404" />
      <img src={PageNotFoundSVG} alt="Page not found!" className="text_404" />
    </Layout>
  )
}

export const query = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
}
`